// import { useState } from "react";
import { Row } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
// import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  // Burger,
  // NotHidden,
  // Menu,
  // CustomNavLinkSmall,
  // Label,
  // Outline,
  // Span,
} from "./styles";

const Header = ({ t }: any) => {
  // const [visible, setVisibility] = useState(false);

  // const showDrawer = () => {
  //   setVisibility(!visible);
  // };

  // const onClose = () => {
  //   setVisibility(!visible);
  // };

  // const MenuItem = () => {
  //   const scrollTo = (id: string) => {
  //     const element = document.getElementById(id) as HTMLDivElement;
  //     element.scrollIntoView({
  //       behavior: "smooth",
  //     });
  //     setVisibility(false);
  //   };
  //   return (
  //     <>
  //       <CustomNavLinkSmall
  //         style={{ width: "180px" }}
  //         onClick={() => scrollTo("contact")}
  //       >
  //         <Span>
  //           <Button>{t("Subscribe")}</Button>
  //         </Span>
  //       </CustomNavLinkSmall>
  //     </>
  //   );
  // };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="Logo_and_Word_Mark_Inline.svg" width="180" height="56px" />
          </LogoContainer>
          {/*<NotHidden>*/}
          {/*  <MenuItem />*/}
          {/*</NotHidden>*/}
          {/*<Burger onClick={showDrawer}>*/}
          {/*  <Outline />*/}
          {/*</Burger>*/}
        </Row>
        {/*<Drawer closable={false} visible={visible} onClose={onClose}>*/}
        {/*  <Col style={{ marginBottom: "2.5rem" }}>*/}
        {/*    <Label onClick={onClose}>*/}
        {/*      <Col span={12}>*/}
        {/*        <Menu>Menu</Menu>*/}
        {/*      </Col>*/}
        {/*      <Col span={12}>*/}
        {/*        <Outline />*/}
        {/*      </Col>*/}
        {/*    </Label>*/}
        {/*  </Col>*/}
        {/*  <MenuItem />*/}
        {/*</Drawer>*/}
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
